import { IconProps } from '../types';

function FolderMoveSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-5 w-5 text-yellow-400 mx-0.5'}
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path d="M16 6h-5L9 4H4c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-3.3 2.3l2 2c.1.1.2.2.2.3.1.1.1.3.1.4 0 .1 0 .3-.1.4 0 .1-.1.2-.2.3l-2 2c-.4.4-1 .4-1.4 0s-.4-1 0-1.4l.3-.3H8c-.6 0-1-.4-1-1s.4-1 1-1h3.6l-.3-.3c-.4-.4-.4-1 0-1.4s1-.4 1.4 0z" />
    </svg>
  );
}

export default FolderMoveSolidIcon;
