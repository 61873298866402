import React from 'react';
import Link from 'next/link';

import { ClassName, GADataType, I18nText } from '../../../types';
import { IconsEnum } from '../../../assets/icons/types';

import { Icon } from '../../Icon';
import { Translate } from '../../../helpers/Translate';

type NextPureLinkHelperOnClick = (
  e: React.MouseEvent<HTMLAnchorElement>
) => void;

interface NextPureLinkHelperProps {
  className?: ClassName;
  href: string;
  i18nText?: I18nText;
  textClassName?: ClassName;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  onClick?: NextPureLinkHelperOnClick;
  onMouseEnter?: () => void;
  prefetch?: boolean;
  text?: string;
}

function NextPureLinkHelper({
  className,
  dataGa,
  href,
  i18nText,
  textClassName,
  icon,
  iconClassName,
  onClick,
  onMouseEnter,
  prefetch,
  text
}: NextPureLinkHelperProps & GADataType) {
  return (
    <Link
      className={className}
      data-ga={dataGa}
      href={href}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      prefetch={prefetch}
    >
      {icon ? <Icon className={iconClassName} icon={icon} /> : null}
      {i18nText ? <Translate id={i18nText} /> : null}
      {text && textClassName ? (
        <span className={textClassName}>{text}</span>
      ) : null}
      {text && !textClassName ? text : null}
    </Link>
  );
}

export default NextPureLinkHelper;
