import { FocusEvent } from 'react';

import {
  FormatGroupLabelType,
  FormatOptionLabelType,
  MultiSelectDataType,
  MultiSelectInputChangeCallbackType,
  MultiSelectMixedDataType
} from './types';
import { createFilter } from 'react-select';

export type MultiSelectValueType =
  | MultiSelectMixedDataType
  | MultiSelectMixedDataType[]
  | null;

export interface MultiSelectProps {
  autoFocus?: boolean;
  classNamePrefix?: string | null;
  closeMenuOnSelect?: boolean;
  blurInputOnSelect?: boolean;
  defaultValue?: MultiSelectDataType[] | MultiSelectDataType | null;
  disabled?: boolean;
  emptyValue?: MultiSelectDataType | null;
  error?: string | null;
  errorClassName?: string;
  i18nLabel?: string | null;
  i18nPlaceholder?: string;
  inputWrapperClassName?: string | null;
  isClearable?: boolean;
  labelClassName?: string;
  menuIsOpen?: boolean;
  menuPosition?: 'absolute' | 'fixed';
  menuPlacement?: 'auto' | 'bottom' | 'top';
  name?: string;
  onMenuClose?: () => void;
  onMenuOpen?: () => void;
  openMenuOnFocus?: boolean;
  placeholder?: string | null;
  showError?: boolean;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  filterConfig?: Parameters<typeof createFilter>[0];
}

export interface MultiSelectOptionalProps {
  data?: MultiSelectMixedDataType[];
  formatGroupLabel?: FormatGroupLabelType;
  formatOptionLabel?: FormatOptionLabelType;
  multi?: boolean;
  onInputChange?: MultiSelectInputChangeCallbackType;
  isSearchable?: boolean;
  optionsLoading?: boolean;
  onMenuScrollToBottom?: () => void;
  isLoading?: boolean;
  sortable?: boolean;
}

export const defaultFilterConfig = {
  ignoreCase: true,
  ignoreAccents: true,
  matchFrom: 'any' as const,
  stringify: (option) => `${option.label} ${option.value}`,
  trim: true
};

export const filterConfigOnlyLabels = {
  ...defaultFilterConfig,
  stringify: (option: MultiSelectDataType) => option.label as string
};
