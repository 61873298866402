import React, { Fragment, useCallback } from 'react';

import { SHOW_DEBUG } from '../../../../../../../config';

import { DownloadStatuses } from '../../../../../../downloads/downloadsTypes';
import { DownloadManagerCornerModalItemDownload } from './DownloadManagerCornerModalItem.types';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { DELETE_DOWNLOAD_QUERY } from '../../../../../../downloads/queries/deleteDownload.query';
import { REMOVE_DOWNLOAD_QUERY } from '../../../../../../downloads/queries/removeDownload.query';

import { useDeleteDownload } from '../../../../../../downloads/hooks/useDeleteDownload';
import { useRemoveDownload } from '../../../../../../downloads/hooks/useRemoveDownload';
import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import { Icon } from '../../../../../../../helpers/Icon';
import { DateTimeHelper } from '../../../../../../../helpers/DateTimeHelper';
import { ProgressCircleHelper } from '../../../../../../../helpers/ProgressCircleHelper';
import { PureLinkHelper } from '../../../../../../../helpers/links/PureLinkHelper';
import { PureIconButtonHelper } from '../../../../../../../helpers/buttons/PureIconButtonHelper';
import { SizeHelper } from '../../../../../../../helpers/SizeHelper';
import { Translate } from '../../../../../../../helpers/Translate';

import { downloadsKeys } from '../../../../../../../locales/keys';
import { CommonPermissions } from '../../../../../commonConstants';

interface DownloadManagerCornerModalItemProps {
  download: DownloadManagerCornerModalItemDownload;
}

function DownloadManagerCornerModalItem({
  download
}: DownloadManagerCornerModalItemProps) {
  const currentUser = useCurrentUser();

  const { deleteDownload, deleteDownloadLoading, deleteDownloadErrorMessage } =
    useDeleteDownload({ query: DELETE_DOWNLOAD_QUERY });

  const { removeDownload, removeDownloadLoading, removeDownloadErrorMessage } =
    useRemoveDownload({ query: REMOVE_DOWNLOAD_QUERY });

  const handleDeleteDownload = useCallback<() => Promise<unknown>>(
    () =>
      currentUser.hasPermissions(CommonPermissions.REMOVE_DOWNLOAD)
        ? removeDownload({ uuid: download.uuid })
        : deleteDownload({ uuid: download.uuid }),
    [currentUser, deleteDownload, download.uuid, removeDownload]
  );

  return (
    <li key={download.uuid} className="flex space-x-3 group items-center">
      <div className="flex-shrink-0">
        <div className="w-10 h-10 flex items-center justify-center rounded-md bg-gray-200 dark:bg-gray-800 text-gray-500">
          <Icon icon={IconsEnum.DOCUMENT_SOLID} className="h-6 w-6" />
        </div>
      </div>

      <div className="flex-1 flex items-center overflow-hidden">
        <div className="overflow-hidden">
          <p className="truncate w-full flex text-2xs">
            <span className="truncate">
              {SHOW_DEBUG ? (
                <Fragment>{download.deviceNanoId}-</Fragment>
              ) : null}
              {download.name}
            </span>
          </p>
          <p className="text-gray-500 text-2xs">
            <DateTimeHelper date={download.createdAt} />
          </p>
          <div className="text-gray-500 text-2xs flex gap-2">
            {download.size > 0 ? (
              <span className="whitespace-nowrap">
                <SizeHelper size={download.size} />
              </span>
            ) : null}
            {download.status === DownloadStatuses.COMPLETED ? (
              <PureLinkHelper
                href={download.file}
                className="underline text-gray-600 dark:text-gray-400"
                i18nText={downloadsKeys.toDownload}
                download={download.name}
              />
            ) : null}

            {download.status === DownloadStatuses.FAILED ? (
              <p className="truncate text-red-500 text-2xs align-center">
                <Icon
                  icon={IconsEnum.EXCLAMATION_CIRCLE}
                  className="h-4 w-4 -mt-0.5 inline-block"
                />
                <span>
                  <Translate id={downloadsKeys.failed} />
                </span>
              </p>
            ) : null}

            {deleteDownloadErrorMessage || removeDownloadErrorMessage ? (
              <p className="truncate text-red-500 text-2xs align-center">
                <Icon
                  icon={IconsEnum.EXCLAMATION_CIRCLE}
                  className="h-4 w-4 -mt-0.5 inline-block"
                />
                <span>
                  {deleteDownloadErrorMessage || removeDownloadErrorMessage}
                </span>
              </p>
            ) : null}
          </div>
        </div>
      </div>

      <div className="flex-shrink-0">
        <div className="h-8 w-8 my-1 relative group">
          {download.status === DownloadStatuses.COMPLETED ? (
            <div className="flex items-center justify-center absolute inset-0 text-blue-500">
              <Icon icon={IconsEnum.CHECK} />
            </div>
          ) : null}

          {download.status === DownloadStatuses.COMPLETED ? null : (
            <ProgressCircleHelper
              color={
                download.status === DownloadStatuses.FAILED ? 'red' : 'blue'
              }
              progress={download.progress}
            />
          )}

          <div className="flex items-center justify-center absolute inset-0 opacity-0 group-hover:opacity-100 focus-within:opacity-100">
            <PureIconButtonHelper
              className="dark:text-gray-200 bg-gray-200 focus:ring-offset-0 dark:bg-gray-800 text-gray-800 p-2 rounded-full focus:opacity-100"
              icon={IconsEnum.X}
              disabled={deleteDownloadLoading || removeDownloadLoading}
              onClick={handleDeleteDownload}
            />
          </div>
        </div>
      </div>
    </li>
  );
}

export default DownloadManagerCornerModalItem;
