import { useCallback, useRef, useState } from 'react';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import size from 'lodash/size';
import sumBy from 'lodash/sumBy';
import map from 'lodash/map';
import isUndefined from 'lodash/isUndefined';

import {
  DownloadStatuses,
  DownloadUUID
} from '../../../../../../downloads/downloadsTypes';
import { IndexQueryResponse } from '../../../../../hooks/base/reactQuery/useIndexQuery';

import {
  FETCH_DOWNLOADS_QUERY,
  FetchDownloadsQueryResponse
} from '../../../../../../downloads/queries/fetchDownloads.query';
import { DELETE_DOWNLOADS_QUERY } from '../../../../../../downloads/queries/deleteDownloads.query';
import { REMOVE_DOWNLOADS_QUERY } from '../../../../../../downloads/queries/removeDownloads.query';

import {
  ModalsEnum,
  useReactQueryModal
} from '../../../../../../../helpers/modals/hooks/useReactQueryModal';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';
import { useReadPaginatedDownloads } from '../../../../../../downloads/hooks/useReadPaginatedDownloads';
import { useDownloadNanoId } from '../../../../../../downloads/hooks/useDownloadNanoId';
import { useRemoveDownloads } from '../../../../../../downloads/hooks/useRemoveDownloads';
import { useDeleteDownloads } from '../../../../../../downloads/hooks/useDeleteDownloads';

import { downloadMultipleFiles } from '../../../../../../../utils/downloadMultipleFiles';

import { DownloadCache } from '../../../../../../downloads/DownloadCache';

import { CommonPermissions } from '../../../../../commonConstants';

const downloadedUuids: DownloadUUID[] = [];
const defaultDownloadDelay = 5000;
const refetchInterval = 1000 * 10;

function useDownloadManagerCornerModal() {
  const currentUser = useCurrentUser();

  const { hideModal, isOpen, showModal } = useReactQueryModal({
    modalEnum: ModalsEnum.DOWNLOADS_MANAGER_MODAL
  });

  const previousTotalDownloads = useRef<number>();
  const previousStartDownloadTime = useRef<number>();
  const delayForStartDownload = useRef<number>();

  const {
    deleteDownloads,
    deleteDownloadsLoading,
    deleteDownloadsErrorMessage
  } = useDeleteDownloads({ query: DELETE_DOWNLOADS_QUERY });

  const {
    removeDownloads,
    removeDownloadsLoading,
    removeDownloadsErrorMessage
  } = useRemoveDownloads({ query: REMOVE_DOWNLOADS_QUERY });

  const [processingDownloadsIds, setProcessingDownloadsIds] = useState<
    DownloadUUID[]
  >([]);

  const { downloadNanoId } = useDownloadNanoId();

  const handleChangeProcessing = useCallback<
    (data: IndexQueryResponse<FetchDownloadsQueryResponse, undefined>) => void
  >(
    (data) => {
      const totalDownloads = size(data?.downloads?.nodes || []);

      if (totalDownloads !== previousTotalDownloads.current) {
        !isUndefined(previousTotalDownloads.current) && !isOpen && showModal();

        previousTotalDownloads.current = totalDownloads;
      }

      const processingDownloads = filter(
        data?.downloads?.nodes || [],
        (download) =>
          download.status === DownloadStatuses.INITIALIZED ||
          download.status === DownloadStatuses.PROCESSING
      );

      if (size(processingDownloads) !== size(processingDownloadsIds)) {
        setProcessingDownloadsIds(map(processingDownloads, 'uuid'));
      }
    },
    [isOpen, processingDownloadsIds, showModal]
  );

  const handleDownload = useCallback<
    (data: IndexQueryResponse<FetchDownloadsQueryResponse, undefined>) => void
  >(
    (data) => {
      const downloadFiles = filter(data?.downloads?.nodes, (download) => {
        if (
          downloadNanoId === download.deviceNanoId &&
          download.status === DownloadStatuses.COMPLETED &&
          !includes<DownloadUUID>(downloadedUuids, download.uuid)
        ) {
          downloadedUuids.push(download.uuid);

          return true;
        }

        return false;
      });

      if (size(downloadFiles) > 0) {
        downloadMultipleFiles({
          downloadFiles,
          delay: defaultDownloadDelay,
          delayForStart: delayForStartDownload.current || 0
        });

        previousStartDownloadTime.current = Date.now();

        const downloadsTime = size(downloadFiles) * defaultDownloadDelay;
        delayForStartDownload.current = delayForStartDownload.current
          ? delayForStartDownload.current - refetchInterval + downloadsTime
          : downloadsTime;
      }

      if (
        previousStartDownloadTime.current &&
        Date.now() - previousStartDownloadTime.current > refetchInterval
      ) {
        delayForStartDownload.current = delayForStartDownload.current
          ? delayForStartDownload.current - refetchInterval
          : 0;
      }
    },
    [downloadNanoId]
  );

  const { downloads, downloadsFetched, downloadsError } =
    useReadPaginatedDownloads<FetchDownloadsQueryResponse>({
      query: FETCH_DOWNLOADS_QUERY,
      cacheKey: DownloadCache.indexCacheKey(),
      initialFilters: {
        self: true,
        status: { notEq: DownloadStatuses.FAILED }
      },
      options: {
        refetchInterval,
        refetchIntervalInBackground: true,
        onSuccess: (data) => {
          handleChangeProcessing(data);
          handleDownload(data);
        }
      }
    });

  const a = sumBy(downloads, (download) =>
    download.status === DownloadStatuses.COMPLETED ? 0 : download.progress
  );

  const b = size(
    filter(
      downloads,
      (download) => download.status !== DownloadStatuses.COMPLETED
    )
  );

  const totalProgress = b === 0 ? 0 : a / b;

  const handleClear = useCallback<() => void>(
    () =>
      currentUser.hasPermissions(CommonPermissions.REMOVE_DOWNLOADS)
        ? removeDownloads({ uuids: map(downloads, 'uuid') })
        : deleteDownloads({ uuids: map(downloads, 'uuid') }),
    [currentUser, deleteDownloads, downloads, removeDownloads]
  );

  const handleStopAll = useCallback(
    () =>
      currentUser.hasPermissions(CommonPermissions.REMOVE_DOWNLOADS)
        ? removeDownloads({ uuids: processingDownloadsIds })
        : deleteDownloads({ uuids: processingDownloadsIds }),
    [currentUser, deleteDownloads, processingDownloadsIds, removeDownloads]
  );

  return {
    downloadManagerBlockOpened: isOpen,
    downloads,
    downloadsFetched,
    downloadsError,
    totalProgress,
    processingDownloadsIds,
    processingDownloadsCount: size(processingDownloadsIds),
    openDownloadManagerBlock: showModal,
    closeDownloadManagerBlock: hideModal,
    toggleDownloadManagerBlock: isOpen ? hideModal : showModal,
    handleClear,
    handleStopAll,
    deleteDownloadsLoading: deleteDownloadsLoading || removeDownloadsLoading,
    deleteDownloadsErrorMessage:
      deleteDownloadsErrorMessage || removeDownloadsErrorMessage
  };
}

export default useDownloadManagerCornerModal;
