import startsWith from 'lodash/startsWith';

import { StockSecondaryMenuItems } from './CompactStockSecondaryMenu.types';

import { LifestylePath } from '../../../../lifestyles/LifestylePath';
import { MaterialPath } from '../../../../materials/MaterialPath';
import { ProductPath } from '../../../../products/ProductPath';

import { CommonPermissions } from '../../../commonConstants';

import {
  lifestylesKeys,
  materialsKeys,
  productsKeys
} from '../../../../../locales/keys';
import { StockPath } from '../../../../stock/StockPath';

export const stockMenuLinks = [
  {
    activeTest: (pathname: string) => startsWith(pathname, ProductPath.index()),
    name: StockSecondaryMenuItems.PRODUCTS,
    href: ProductPath.index(),
    i18nText: productsKeys.plural,
    permissions: [CommonPermissions.READ_COMPACT_STOCK_MENU_PRODUCTS_LINK]
  },
  {
    activeTest: (pathname: string) =>
      startsWith(pathname, LifestylePath.index()),
    name: StockSecondaryMenuItems.LIFESTYLES,
    href: LifestylePath.index(),
    i18nText: lifestylesKeys.plural,
    permissions: [CommonPermissions.READ_COMPACT_STOCK_MENU_LIFESTYLES_LINK]
  },
  {
    activeTest: (pathname: string) =>
      startsWith(pathname, MaterialPath.index()),
    name: StockSecondaryMenuItems.MATERIALS,
    href: MaterialPath.index(),
    i18nText: materialsKeys.plural,
    permissions: [CommonPermissions.READ_COMPACT_STOCK_MENU_MATERIALS_LINK]
  }
];

export const newStockMenuLinks = [
  {
    activeTest: (pathname: string) => startsWith(pathname, ProductPath.index()),
    name: StockSecondaryMenuItems.PRODUCTS,
    href: StockPath.newProducts(),
    i18nText: productsKeys.plural,
    permissions: [CommonPermissions.READ_COMPACT_NEW_STOCK_MENU_PRODUCTS_LINK]
  },
  {
    activeTest: (pathname: string) =>
      startsWith(pathname, LifestylePath.index()),
    name: StockSecondaryMenuItems.LIFESTYLES,
    href: StockPath.newLifestyles(),
    i18nText: lifestylesKeys.plural,
    permissions: [CommonPermissions.READ_COMPACT_NEW_STOCK_MENU_LIFESTYLES_LINK]
  },
  {
    activeTest: (pathname: string) =>
      startsWith(pathname, MaterialPath.index()),
    name: StockSecondaryMenuItems.MATERIALS,
    href: StockPath.newMaterials(),
    i18nText: materialsKeys.plural,
    permissions: [CommonPermissions.READ_COMPACT_NEW_STOCK_MENU_MATERIALS_LINK]
  }
];
