import React, { ForwardedRef, ReactNode, useCallback } from 'react';
import { GADataType } from '../../../types';

export interface RefButtonHelperDefaultProps {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  onClick?: (arg: React.MouseEvent<HTMLButtonElement>) => void;
}

type RefButtonHelperProps = RefButtonHelperDefaultProps & GADataType;

const RefButtonHelper = React.forwardRef<
  HTMLButtonElement,
  RefButtonHelperProps
>(
  (
    componentProps: RefButtonHelperProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const { children, className, disabled, onClick, dataGa } = componentProps;
    const handleClick = useCallback(
      (e) => {
        e.preventDefault();

        if (onClick) {
          onClick(e);
        }
      },
      [onClick]
    );

    return (
      <button
        data-ga={dataGa}
        className={className}
        disabled={disabled}
        onClick={handleClick}
        ref={ref}
      >
        {children}
      </button>
    );
  }
);

RefButtonHelper.displayName = 'RefButtonHelper';

export default RefButtonHelper;
