import { gql } from 'graphql-request';

export const REMOVE_DOWNLOADS_QUERY = gql`
  mutation RemoveDownloads($uuids: [ID!]!) {
    removeDownloads(input: { uuids: $uuids }) {
      status
      errors {
        fullMessages
      }
    }
  }
`;
