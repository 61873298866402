import {
  BooleanFilter,
  CreatedAt,
  CreatedAtFilter,
  CreateItemGqlQuery,
  CreateItemGqlStatus,
  DeleteItemGqlQuery,
  DeleteItemGqlStatus,
  DeviceNanoId,
  FetchItemCacheKey,
  FetchItemGqlQuery,
  FetchItemsCacheKey,
  FetchItemsEditItem,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsShowItem,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  FileUrl,
  ID,
  ItemGqlError,
  TextFilterType,
  UpdateItemGqlQuery,
  UpdateItemGqlStatus,
  UUID
} from '../../types';

export type DownloadID = ID;
export type DownloadUUID = UUID;
export type DownloadName = string;
export type DownloadFile = FileUrl;
export type DownloadStatus = DownloadStatuses;
export type DownloadProgress = number;
export type DownloadType = string;
export type DownloadDeviceNanoId = DeviceNanoId;
export type DownloadSize = number;
export type DownloadCreatedAt = CreatedAt;

export enum DownloadStatuses {
  INITIALIZED = 'initialized',
  PROCESSING = 'processing',
  FAILED = 'failed',
  COMPLETED = 'completed'
}

export interface FetchDownloadsFilters {
  self?: BooleanFilter;
  status?: TextFilterType;
  createdAt?: CreatedAtFilter;
}

export enum FetchDownloadsSortableFields {
  ID = 'id',
  CREATED_AT = 'createdAt'
}

export enum FetchDownloadsSortTypes {
  CREATED_AT_DESC = 'CREATED_AT_DESC'
}

export type FetchDownloadsGqlQuery = FetchItemsGqlQuery;
export type FetchDownloadGqlQuery = FetchItemGqlQuery;
export type CreateDownloadGqlQuery = CreateItemGqlQuery;
export type UpdateDownloadGqlQuery = UpdateItemGqlQuery;
export type DeleteDownloadGqlQuery = DeleteItemGqlQuery;
export type RemoveDownloadGqlQuery = DeleteItemGqlQuery;

export type CreateDownloadGqlStatus = CreateItemGqlStatus;
export type UpdateDownloadGqlStatus = UpdateItemGqlStatus;
export type DeleteDownloadGqlStatus = DeleteItemGqlStatus;
export type RemoveDownloadGqlStatus = DeleteItemGqlStatus;
export type DownloadGqlError = ItemGqlError;

export type DeleteDownloadsGqlQuery = DeleteItemGqlQuery;
export type RemoveDownloadsGqlQuery = DeleteItemGqlQuery;

export type DeleteDownloadsGqlStatus = DeleteItemGqlStatus;
export type RemoveDownloadsGqlStatus = DeleteItemGqlStatus;

export type FetchDownloadsCacheKey = FetchItemsCacheKey;
export type FetchDownloadCacheKey = FetchItemCacheKey;

export type FetchDownloadsFetched = FetchItemsFetched;
export type FetchDownloadsErrorMessage = FetchItemsErrorMessage;
export type FetchDownloadsIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchDownloadsPage = FetchItemsPage;
export type FetchDownloadsLimit = FetchItemsLimit;
export type FetchDownloadsSort = FetchDownloadsSortTypes[];
export type FetchDownloadsTotalCount = FetchItemsTotalCount;
export type FetchDownloadsSortDownloads = FetchItemsSortItems;
export type FetchDownloadsPaginateDownloads = FetchItemsPaginateItems;
export type FetchDownloadsPrefetchDownloads = FetchItemsPrefetchItems;
export type FetchDownloadsShowDownload = FetchItemsShowItem;
export type FetchDownloadsEditDownload = FetchItemsEditItem;
