import { IconProps } from '../types';

function FolderXSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-5 w-5'}
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path
        fillRule="evenodd"
        d="M16 6h-5L9 4H4c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-3.2 6.4c.4.4.4 1 0 1.4-.2.2-.5.3-.7.3s-.5-.1-.7-.3L10 12.4l-1.4 1.4c-.4.4-1 .4-1.4 0s-.4-1 0-1.4L8.6 11 7.2 9.6c-.4-.4-.4-1 0-1.4s1-.4 1.4 0L10 9.6l1.4-1.4c.4-.4 1-.4 1.4 0s.4 1 0 1.4L11.4 11l1.4 1.4z"
      />
    </svg>
  );
}

export default FolderXSolidIcon;
