export default {
  acceptance: 'Ready for acceptance',
  account: 'Task account',
  active: 'Active tasks',
  add: 'Add task',
  add_body: 'Add task description',
  addDescription: 'Add task description',
  addLdCustomerId: 'Add LD customer id',
  addLdSku: 'Add LD SKU',
  addLdProductId: 'Add LD product id',
  addNotes: 'Add task notes',
  addTags: 'Add task tags',
  addMessage: 'Add task message',
  all: 'All tasks',
  alreadyMember: 'Already task member',
  acceptResults: 'Accept results',
  areYouSureYouWantLeaveTaskThisActionCannotBeUndone:
    'Are you sure you want leave the task? This action cannot be undone.',
  areYouCertainAboutLeavingTheTaskPleaseBeAwareOfTheFollowingOutcomes:
    'Are you certain about leaving the task? Please be aware of the following outcomes:',
  areYouSureYouWantToStartTheTask: 'Are you sure you want to start the task?',
  addAsManyTasksAsYouNeedToCreateInOneGoClickTheTaskToEditItsDetails:
    'Add as many tasks as you need to create in one go. Click the task to edit its details.',
  areYouSureYouWantToDeleteThisTaskYouWillNotBeAbleToUndoThisAction:
    'Are you sure you want to delete this task? You will not be able to undo this action.',
  confirmAndStart: 'Confirm and start',
  cannotStartTaskWithNoItemsBoundToTheCurrentSmartContract:
    'Cannot start task with no items bound to the current smart contract.',
  cannotCheckInTheSmartContractWithNoShares:
    'Cannot check in the smart contract with no shares.',
  cautionItemsInSmartContractAreNotBoundToTheDepositInvoiceAndYouShallStartTheTaskAtYourOwnRisk:
    'Caution! Items in smart contract are not bound to the deposit invoice and you shall start the task at your own risk.',
  description: 'Task description',
  backToTasksList: 'Back to tasks list',
  burning: 'Burning tasks',
  caution: 'Caution!',
  cancel: 'Cancel task',
  cell: 'Cell tasks',
  channels: 'Task channels',
  current: 'Current task',
  changeStatus: 'Change status',
  count: 'Task count',
  create: 'Create task',
  createTaskOnly: 'Create task only',
  createTasksOnly: 'Create tasks only',
  createMultiple: 'Create multiple tasks',
  createSingle: 'Create single task',
  create_new: 'Create new task',
  canceledPluralLowerCase: 'canceled tasks',
  canceledLowerCase: 'canceled',
  details: 'Task Details',
  doneLowerCase: 'done',
  downloadResults: 'Download results',
  download_excel: 'Download excel document with all tasks',
  download_project_excel: 'Download excel document with all project tasks',
  download_selected_excel: 'Download excel document with selected tasks',
  coefficient: 'K',
  delete: 'Delete task',
  duplicate: 'Duplicate tasks',
  finalResult: 'Final result',
  fullyPaid: 'Fully paid',
  displayOptions: 'Display options',
  editDescription: 'Edit description',
  editNotes: 'Edit notes',
  editVersion: 'Edit version',
  edit_description: 'Edit task description',
  edit_details: 'Edit task details',
  enter_name: 'Enter task name',
  favorite: 'Favorite tasks',
  goTo: 'Go to task',
  goToProject: 'Go to project',
  id: 'Task Id',
  hide: 'Hide task',
  import: 'Import tasks',
  importAllocate: 'Import allocate',
  tasksIn: 'Tasks in',
  tasksToAdd: 'Tasks to add',
  tasksToCreate: 'Tasks to create',
  invitations: 'Invitations',
  leave: 'Leave task',
  members: 'Task members',
  members_lower_case: 'task members',
  members_genitive_lower_case: 'task members',
  multiple: 'Multiple tasks',
  mute: 'Mute task',
  missingSourceFiles: 'Missing source files',
  my: 'My tasks',
  myWithoutSourceFiles: 'My tasks without source files',
  name: 'Task name',
  name_a_z: 'Task name (A-Z)',
  name_z_a: 'Task name (Z-A)',
  namesHaveToBeUnique: 'Task names have to be unique',
  new: 'New task',
  'new...': 'What needs to be done?',
  no: 'No tasks',
  noVisibleTasksFound: 'No visible tasks found',
  notes: 'Task notes',
  myFinance: 'My finance',
  myNotes: 'My notes',
  myResults: 'My tasks results',
  myTimeline: 'My timeline',
  pause: 'Pause task',
  pleaseRate: 'Please rate the task',
  processingJobs: 'Task processing jobs',
  plural: 'Tasks',
  pluralLowerCase: 'tasks',
  pluralGenitiveLowerCase: 'tasks',
  plural_prepositional_lower_case: 'tasks',
  profile: 'Task profile',
  finishTasks: 'Finish tasks',
  finishMultipleTasks: 'Finish multiple tasks',
  remove_from: 'Remove from task',
  restore: 'Restore task',
  restart: 'Restart task',
  results: 'Task results',
  roundCounter: 'Round counter',
  findResultsInAttachments: 'Please find the result in attachment',
  submitAsTaskResult: 'Submit as task result',
  submitResults: 'Submit results',
  submitAsSourceFile: 'Submit as source file',
  submitSourceFiles: 'Submit source files',
  submittedResults: 'submitted task result',
  searchByTaskName: 'Search by task name',
  select: 'Select task...',
  selectSingular: 'Select task',
  selectUser: 'Select task member',
  selected_users: 'Selected task members',
  set_cost: 'Set task cost',
  sendInvoice: 'Send invoice',
  setDueDate: 'Set task due date',
  selectShareToCheckInWith: 'Select share to check in with',
  show_all: 'Show all tasks',
  show_done: 'Show done tasks',
  show_hidden: 'Show hidden tasks',
  show_visible: 'Show visible tasks',
  show_only_visible: 'Show only visible tasks',
  show_with_canceled: 'Show with canceled tasks',
  show_without_canceled: 'Show without canceled tasks',
  show_with_done: 'Show with done tasks',
  show_without_done: 'Show without done tasks',
  show_with_hidden: 'Show with hidden tasks',
  showHiddenTasks: 'Show hidden tasks',
  showOnlyHiddenTasks: 'Show only hidden tasks',
  showCanceledTasks: 'Show canceled tasks',
  showDoneTasks: 'Show done tasks',
  search: 'Search tasks',
  searchTasksByNameOrUser: 'Search tasks by name or @user',
  single: 'Single task',
  singular: 'Task',
  singularLowerCase: 'task',
  singularAttachments: 'Task Attachments',
  toProject: 'To project',
  allAttachments: 'All Task Attachments',
  singular_lower_case: 'task',
  states: {
    acceptance: 'Ready for acceptance',
    canceled: 'Canceled',
    definition: 'Setting task',
    incompleteSpecification: 'Pending more info',
    paused: 'Paused',
    performed: 'Done',
    qa: 'Quality control'
  },
  buttons: {
    abort: 'Abort task',
    accept: 'Accept',
    approve: 'Approve',
    cancel: 'Cancel task',
    checkIn: 'Check in',
    checkInWithoutFollowers: 'Check in without followers',
    checkInWithFollowers: 'Check in with followers',
    close: 'Close task',
    pause: 'Pause task',
    pendingMoreInfo: 'Pending more info',
    restore: 'Restore task',
    restart: 'Restart task',
    sendToRevision: 'Send to revision',
    startTask: 'Start task'
  },
  isClosed: 'Task is closed',
  jobStatus: {
    start: 'Starting task',
    cancel: 'Canceling task',
    accept: 'Accepting task',
    processing: 'Processing'
  },
  ldSku: 'LD SKU',
  ldCustomerId: 'LD Customer id',
  ldProductId: 'LD Product id',
  invitedUsers: 'Invited users',
  inviteToMultipleTasks: 'Invite to multiple tasks',
  inviteUser: 'Invite user',
  noPlural: 'No tasks',
  selectTaskToSeeMessages: 'Select task to see messages',
  startNewVersion: 'Start new version',
  status: 'Task Status',
  statusChange: 'Status change',
  status_change: 'Task status change',
  optional: 'Optional',
  tableSettings: 'Table settings',
  tags_change: 'Task tags change',
  taskName: 'Task name',
  tasksInProgress: 'Tasks in progress',
  taskTeam: 'Task team',
  task_timer: 'Task timer',
  tags: 'Task tags',
  timeline: 'Timeline',
  team: 'Task team',
  total: 'Task total',
  trackTaskTime: 'Track task time',
  trackTimeInMultipleTasks: 'Track time in multiple tasks',
  trackTime: 'Track time',
  track: 'Track',
  trackedToday: 'Tracked today:',
  thisTaskIsHidden: 'This task is hidden',
  unassigned: 'Unassigned tasks',
  unhide: 'Unhide task',
  view: 'View task',
  version: 'Version',
  valid: 'Valid tasks',
  nonValid: 'Non valid tasks',
  noSmartContracts: 'No smart contracts',
  viewPlural: 'View tasks',
  optionalWhenYouExpectTheTaskToBeFinished:
    '(optional) When you expect the task to be finished. It can be  changed later.',
  addedToProcessing: 'Tasks were added to processing',
  goToSmartContract: 'Go to smart contract.',
  goToProfileSettings: 'Go to profile settings.',
  withoutSourceFiles: 'Without source files',
  workload: 'Workload',
  youCanNotStartTheTaskWithRefundedInvoices:
    'You can not start the task with refunded invoices.',
  youWillNoLongerReceiveNotificationsRelatedToThisTask:
    'You will no longer receive notifications related to this task.',
  theTaskWillBeExcludedFromYourMyTasksList:
    'The task will be excluded from your "My Tasks" list.',
  itWontBeIncludedInSearchResultsFilteredByYouAsATaskMember:
    "It won't be included in search results filtered by you as a task member.",
  inviteTeamMembersToTheTaskUponTaskCreation:
    'Invite team members to the task upon task creation.'
};
