import { IconProps } from '../types';

function RenameSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-4 w-4'}
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        clipRule="evenodd"
        fillRule="evenodd"
        d="M7 5H5C3.3 5 2 6.3 2 8v4c0 1.7 1.3 3 3 3h2v2h2V3H7v2zm0 8H5c-.6 0-1-.4-1-1V8c0-.6.4-1 1-1h2v6zm11-5v4c0 1.7-1.3 3-3 3h-4v-2h4c.6 0 1-.4 1-1V8c0-.6-.4-1-1-1h-4V5h4c1.7 0 3 1.3 3 3z"
      />
    </svg>
  );
}

export default RenameSolidIcon;
