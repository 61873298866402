import { IconProps } from '../types';

function QuestionMarkOutlineIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      className={className || 'h-5 w-5'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8 8a3.5 3 0 0 1 3.5-3h1A3.5 3 0 0 1 16 8a3 3 0 0 1-2 3a3 4 0 0 0-2 4m0 4v.01"
      />
    </svg>
  );
}

export default QuestionMarkOutlineIcon;
