import {
  RemoveDownloadGqlQuery,
  RemoveDownloadGqlStatus,
  DownloadGqlError,
  DownloadUUID
} from '../../downloadsTypes';

import { useDeleteQuery } from '../../../common/hooks/base/reactQuery/useDeleteQuery';
import { DownloadCache } from '../../DownloadCache';

interface RemoveDownloadInput {
  uuid: DownloadUUID;
}

interface RemoveDownloadResponse {
  removeDownload: {
    status: RemoveDownloadGqlStatus;
    errors: RemoveDownloadError;
  };
}

interface RemoveDownloadError {
  fullMessages: DownloadGqlError;
}

interface RemoveDownloadOptions {
  query: RemoveDownloadGqlQuery;
}

const action = 'removeDownload';
const pluralScope = DownloadCache.indexCacheKey();

function useRemoveDownload({ query }: RemoveDownloadOptions) {
  const {
    deleteQueryData,
    deleteQuery,
    deleteQueryReset,
    deleteQueryError,
    deleteQueryLoading,
    deleteQueryErrorMessage
  } = useDeleteQuery<
    RemoveDownloadInput,
    RemoveDownloadResponse,
    RemoveDownloadError
  >({ action, pluralScope, query });

  return {
    removeDownloadData: deleteQueryData,
    removeDownloadError: deleteQueryError,
    removeDownloadLoading: deleteQueryLoading,
    removeDownloadErrorMessage: deleteQueryErrorMessage,
    removeDownload: deleteQuery,
    removeDownloadReset: deleteQueryReset
  };
}

export default useRemoveDownload;
