export default {
  activityDate: 'Activity date',
  addRecord: 'Add record',
  addRecordAndBlock: 'Add record and block',
  addRecordAndVoid: 'Add record and void',
  record: 'Record',
  recordAbout: 'Record about',
  recordAdded: 'Record added',
  records: 'Records',
  recordUpdated: 'Record updated',
  status: 'Status',
  recorded: 'Recorded',
  recordedDate: 'Recorded date',
  dueDate: 'Due date',
  completed: 'Completed',
  completedDate: 'Completed date',
  createCompanyRecord: 'Create company record',
  description: 'Description',
  lastRecord: 'Last record',
  leaveOfAbsence: 'Leave of absence',
  subject: 'Subject',
  statuses: {
    planned: 'Planned',
    completed: 'Completed',
    not_started: 'Not started',
    in_progress: 'In progress',
    canceled: 'Canceled',
    waiting_for_input: 'Waiting for input',
    deferred: 'Deferred'
  },
  tat: 'TAT',
  types: {
    call: 'Call',
    event: 'Event',
    task: 'Task'
  },
  typeOfActivity: 'Type of activity',
  messages: {
    addConfirm: {
      areYouSureYouWantToAdd: 'Are you sure you want to add',
      record: 'record?'
    }
  },
  noTat: 'No TAT',
  notified: 'Notified',
  updateRecord: 'Update record'
};
