import { IconProps } from '../types';

function SearchAiSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={className || 'h-5 w-5'}
    >
      <path
        d="M7 11c-.3 0-.5.1-.7.3-.4-.2-.8-.3-1.3-.3-1.7 0-3 1.5-3 3.5S3.3 18 5 18c.5 0 .9-.1 1.3-.3.2.2.4.3.7.3.6 0 1-.4 1-1v-5c0-.6-.4-1-1-1zm-2 5c-.5 0-1-.6-1-1.5S4.5 13 5 13s1 .6 1 1.5S5.5 16 5 16zm6-4v5c0 .6-.4 1-1 1s-1-.4-1-1v-5c0-.6.4-1 1-1s1 .4 1 1zm6.7 5.7c-.2.2-.4.3-.7.3s-.5-.1-.7-.3L13 14.5c-.3 0-.5-.1-.7-.3-.4-.4-.3-1 .1-1.4 1-1 1.7-2.3 1.7-3.7 0-2.8-2.2-5-5-5S4 6.2 4 9v.4c0 .6-.4 1-.9 1.1-.6 0-1.1-.5-1.1-1V9c0-3.9 3.1-7 7-7s7 3.1 7 7c0 1.5-.5 3-1.4 4.2l3.1 3.1c.4.4.4 1 0 1.4z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SearchAiSolidIcon;
