import {
  RemoveDownloadsGqlQuery,
  RemoveDownloadsGqlStatus,
  DownloadGqlError,
  DownloadUUID
} from '../../downloadsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

import { DownloadCache } from '../../DownloadCache';

interface RemoveDownloadsInput {
  uuids: DownloadUUID[];
}

interface RemoveDownloadsResponse {
  removeDownloads: {
    status: RemoveDownloadsGqlStatus;
    errors: RemoveDownloadsError;
  };
}

interface RemoveDownloadsError {
  fullMessages: DownloadGqlError;
}

interface RemoveDownloadsOptions {
  query: RemoveDownloadsGqlQuery;
}

const action = 'removeDownloads';
const pluralScope = DownloadCache.indexCacheKey();

function useRemoveDownloads({ query }: RemoveDownloadsOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    RemoveDownloadsInput,
    RemoveDownloadsResponse,
    RemoveDownloadsError,
    undefined
  >({ action, pluralScope, query });

  return {
    removeDownloadsData: updateQueryData,
    removeDownloadsError: updateQueryError,
    removeDownloadsLoading: updateQueryLoading,
    removeDownloadsErrorMessage: updateQueryErrorMessage,
    removeDownloads: updateQuery,
    removeDownloadsReset: updateQueryReset
  };
}

export default useRemoveDownloads;
