import React, { ReactNode } from 'react';
import Link from 'next/link';
import { GADataType } from '../../../types';

type NextLinkHelperOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => void;

interface NextLinkHelperProps {
  id?: string;
  className?: string;
  href: string;
  target?: '_blank';
  prefetch?: boolean;
  title?: string;
  onClick?: NextLinkHelperOnClick;
  onMouseEnter?: () => void;
  children: ReactNode;
  as?: string;
}

function NextLinkHelper({
  as,
  children,
  className,
  dataGa,
  href,
  id,
  onClick,
  onMouseEnter,
  prefetch,
  target,
  title
}: NextLinkHelperProps & GADataType) {
  return (
    <Link
      as={as}
      className={className}
      data-ga={dataGa}
      href={href}
      id={id}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      prefetch={prefetch}
      target={target}
      title={title}
    >
      {children}
    </Link>
  );
}

export default NextLinkHelper;
