import { invoicesKeys } from '../../locales/keys';
import {
  FetchInvoicesSortTypes,
  InvoiceStatuses,
  InvoiceTypes,
  InvoiceViewSettings
} from './invoicesTypes';
import {
  ProformaInvoiceFormGroupByMode,
  ProformaInvoiceFormViewMode
} from '../teams/components/forms/ProformaInvoiceForm/ProformaInvoiceForm.types';

export enum InvoicesPermissions {
  READ_PROFORMA_INVOICE_STATUSES_FILTER = 'read_proforma_invoice_statuses_filter',
  READ_PROFORMA_PROJECT_CLOSURE_MONTH = 'read_proforma_project_closure_month',
  READ_ADMIN_MENU_INVOICES_LINK = 'read_admin_menu_invoices_link',
  READ_ADMIN_INVOICES_LINK = 'read_admin_invoices_link',
  READ_INVOICES_INDEX_PAGE = 'read_invoices_index_page',
  READ_INVOICES_TABLE_CREATED_AT_DATE_TIME_FIELD = 'read_invoices_table_created_at_date_time_field',
  READ_INVOICE_LINK = 'read_invoice_link',
  READ_INVOICE_SHOW_PAGE = 'read_invoice_show_page',
  READ_INVOICE_ITEMS_TABLE_PREPAYMENT_FIELD = 'read_invoice_items_table_prepayment_field',
  READ_INVOICE_MESSAGE_TOTALS_TABLE_PREPAYMENT_FIELD = 'read_invoice_message_totals_table_prepayment_field',
  READ_FOOTER_ADD_FUNDS_MODAL_BUTTON = 'read_footer_add_funds_modal_button',
  READ_PAYMENTS_MENU_WITHDRAW_MODAL_BUTTON = 'read_payments_menu_withdrawal_button',
  READ_PAYMENTS_MENU_WITHDRAW_MODAL_BUTTON_BALANCE = 'read_payments_menu_withdrawal_button_balance',
  READ_PAYMENTS_MENU_GENERAL_LEDGERS = 'read_payments_menu_general_ledgers',
  READ_PAYMENTS_MENU_INVOICES_LINK = 'read_payments_menu_invoices_link',
  READ_PAYMENTS_MENU_INVOICES_OPEN_LINK = 'read_payments_menu_invoices_open_link',
  READ_PAYMENTS_MENU_INVOICES_SENT_LINK = 'read_payments_menu_invoices_sent_link',
  READ_PAYMENTS_MENU_INVOICES_DRAFT_LINK = 'read_payments_menu_invoices_draft_link',
  READ_PAYMENTS_MENU_INVOICES_PAID_LINK = 'read_payments_menu_invoices_paid_link',
  READ_PAYMENTS_MENU_INVOICES_CANCELED_LINK = 'read_payments_menu_invoices_canceled_link',
  READ_PAYMENTS_MENU_INVOICES_REFUNDS_LINK = 'read_payments_menu_invoices_refunds_link',
  READ_PAYMENTS_INVOICES_INVOICE_PAGE = 'read_payments_invoices_ivoices_page',
  READ_DEPOSIT_INVOICES_INDEX_PAGE = 'read_depositices_indices_index_page',
  READ_PAYMENTS_INVOICE_CANCEL_BUTTON = 'read_payments_invoice_cancel_button',
  READ_PAYMENTS_INVOICE_REFUND_BUTTON = 'read_payments_invoice_refund_button',
  READ_PAYMENTS_INVOICE_CHARGEBACK_BUTTON = 'read_payments_invoice_chargeback_button',
  READ_PAYMENTS_INVOICE_APPROVE_BUTTON = 'read_payments_invoice_approve_button',
  READ_PAYMENTS_INVOICE_CONFIRM_PAYMENT_BUTTON = 'read_payments_invoice_confirm_payment_button',
  READ_PAYMENTS_INVOICE_PAY_INTERNAL_BUTTON = 'read_payments_invoice_pay_internal_button',
  READ_PAYMENTS_INVOICE_UNCOLLECTIBLE_BUTTON = 'read_payments_invoice_uncollectible_button',
  READ_PAYMENTS_INVOICE_SEND_TO_EMAIL_BUTTON = 'read_payments_invoice_send_to_email_button',
  READ_PAYMENTS_INVOICE_DOWNLOAD_BUTTON = 'read_payments_invoice_download_button',
  READ_PAYMENTS_INVOICE_RECEIPT_BUTTON = 'read_payments_invoice_receipt_button',
  READ_PAYMENTS_INVOICE_SUBSCRIBE_BUTTON = 'read_payments_invoice_subscribe_button',
  READ_PAYMENTS_INVOICE_PAY_BY_CARD_BUTTON = 'read_payments_invoice_pay_by_card_button',
  READ_PAYMENTS_INVOICE_PAY_STRIPE_LINK = 'read_payments_invoice_pay_stripe_link',
  READ_PAYMENTS_INVOICE_SEND_DRAFT_BUTTON = 'read_payments_invoice_send_draft_button',
  READ_PAYMENTS_INVOICE_AV_BILLING_INFO = 'read_payments_invoice_av_billing_info',
  READ_PAYMENTS_INVOICE_BEFORE_ITEMS_CUSTOM_FIELDS = 'read_payments_invoice_before_items_custom_fields',
  CREATE_PAYMENTS_INVOICE_BEFORE_ITEMS_CUSTOM_FIELDS = 'create_payments_invoice_before_items_custom_fields',
  UPDATE_PAYMENTS_INVOICE_BEFORE_ITEMS_CUSTOM_FIELDS = 'update_payments_invoice_before_items_custom_fields',
  DELETE_PAYMENTS_INVOICE_BEFORE_ITEMS_CUSTOM_FIELDS = 'delete_payments_invoice_before_items_custom_fields',
  READ_PAYMENTS_INVOICE_AV_BILLING_INFO_CUSTOM_FIELDS = 'read_payments_invoice_av_billing_info_custom_fields',
  CREATE_PAYMENTS_INVOICE_AV_BILLING_INFO_CUSTOM_FIELDS = 'create_payments_invoice_av_billing_info_custom_fields',
  UPDATE_PAYMENTS_INVOICE_AV_BILLING_INFO_CUSTOM_FIELDS = 'update_payments_invoice_av_billing_info_custom_fields',
  DELETE_PAYMENTS_INVOICE_AV_BILLING_INFO_CUSTOM_FIELDS = 'delete_payments_invoice_av_billing_info_custom_fields',
  READ_PAYMENTS_INVOICE_BILLING_INFO_CUSTOM_FIELDS = 'read_payments_invoice_billing_info_custom_fields',
  CREATE_PAYMENTS_INVOICE_BILLING_INFO_CUSTOM_FIELDS = 'create_payments_invoice_billing_info_custom_fields',
  UPDATE_PAYMENTS_INVOICE_BILLING_INFO_CUSTOM_FIELDS = 'update_payments_invoice_billing_info_custom_fields',
  DELETE_PAYMENTS_INVOICE_BILLING_INFO_CUSTOM_FIELDS = 'delete_payments_invoice_billing_info_custom_fields',
  READ_PAYMENTS_INVOICE_TERMS_AND_NOTES_CUSTOM_FIELDS = 'read_payments_invoice_terms_and_notes_custom_fields',
  CREATE_PAYMENTS_INVOICE_TERMS_AND_NOTES_CUSTOM_FIELDS = 'create_payments_invoice_terms_and_notes_custom_fields',
  UPDATE_PAYMENTS_INVOICE_TERMS_AND_NOTES_CUSTOM_FIELDS = 'update_payments_invoice_terms_and_notes_custom_fields',
  DELETE_PAYMENTS_INVOICE_TERMS_AND_NOTES_CUSTOM_FIELDS = 'delete_payments_invoice_terms_and_notes_custom_fields',
  READ_PAYMENTS_INVOICE_AMOUNT_DUE_CUSTOM_FIELDS = 'read_payments_invoice_amount_due_custom_fields',
  CREATE_PAYMENTS_INVOICE_AMOUNT_DUE_CUSTOM_FIELDS = 'create_payments_invoice_amount_due_custom_fields',
  UPDATE_PAYMENTS_INVOICE_AMOUNT_DUE_CUSTOM_FIELDS = 'update_payments_invoice_amount_due_custom_fields',
  DELETE_PAYMENTS_INVOICE_AMOUNT_DUE_CUSTOM_FIELDS = 'delete_payments_invoice_amount_due_custom_fields',
  READ_PAYMENTS_INVOICE_BILLING_INFO = 'read_payments_invoice_billing_info',
  READ_PAYMENTS_INVOICE_TEAM_UPSALERS = 'read_payments_invoice_team_upsalers',
  READ_PAYMENTS_INVOICE_ITEMS = 'read_payments_invoice_items',
  READ_PAYMENTS_INVOICE_NOTES = 'read_payments_invoice_notes',
  READ_PAYMENTS_INVOICE_TERMS = 'read_payments_invoice_terms',
  READ_PAYMENTS_INVOICE_SEND_BUTTON = 'read_payments_invoice_send_button',
  READ_PAYMENTS_INVOICE_VOID_BUTTON = 'read_payments_invoice_void_button',
  READ_PAYMENTS_INVOICE_VOID_WITHOUT_CHECK_SC_BUTTON = 'read_payments_invoice_void_without_check_sc_button',
  READ_PAYMENTS_INVOICE_CANCEL_SMART_CONTRACTS_BUTTON = 'read_payments_invoice_cancel_smart_contracts_button',
  READ_PAYMENTS_INVOICE_HEADER_CUSTOM_FIELDS = 'read_payments_invoice_header_custom_fields',
  CREATE_PAYMENTS_INVOICE_HEADER_CUSTOM_FIELDS = 'create_payments_invoice_header_custom_fields',
  UPDATE_PAYMENTS_INVOICE_HEADER_CUSTOM_FIELDS = 'update_payments_invoice_header_custom_fields',
  DELETE_PAYMENTS_INVOICE_HEADER_CUSTOM_FIELDS = 'delete_payments_invoice_header_custom_fields',
  READ_PAYMENTS_RECEIPT_BLOCK = 'read_payments_receipt_block',
  READ_PROFORMA_INVOICE_CREATE_FROM_PROFORMA_BUTTON = 'read_proforma_invoice_create_from_proforma_button',
  READ_PROFORMA_INVOICE_ADD_ITEM_BUTTON = 'read_proforma_invoice_add_item_button',
  READ_PROFORMA_INVOICE_PAY_FROM_DEPOSIT_BUTTON = 'read_proforma_invoice_pay_from_deposit_button',
  READ_PROFORMA_INVOICE_ITEMS_ADD_TO_DRAFT_BUTTON = 'read_proforma_invoice_items_add_to_draft_button',
  READ_PROFORMA_INVOICE_ITEMS_REMOVE_FROM_DRAFT_BUTTON = 'read_proforma_invoice_items_remove_from_draft_button',
  READ_PROFORMA_INVOICE_ITEMS_UPDATE_INVOICE_TAG_BUTTON = 'read_proforma_invoice_items_update_invoice_tag_button',
  READ_PROFORMA_INVOICE_PAY_INTERNAL_BUTTON = 'read_proforma_invoice_pay_internal_button',
  READ_PROFORMA_INVOICE_AV_BILLING_INFO = 'read_proforma_invoice_av_billing_info',
  READ_PROFORMA_INVOICE_BILLING_INFO = 'read_proforma_invoice_billing_info',
  READ_PROFORMA_INVOICE_PAYMENT_METHOD = 'read_proforma_invoice_payment_method',
  READ_PROFORMA_INVOICE_AV_BILLING_INFO_CUSTOM_FIELDS = 'read_proforma_invoice_av_billing_info_custom_fields',
  CREATE_PROFORMA_INVOICE_AV_BILLING_INFO_CUSTOM_FIELDS = 'create_proforma_invoice_av_billing_info_custom_fields',
  UPDATE_PROFORMA_INVOICE_AV_BILLING_INFO_CUSTOM_FIELDS = 'update_proforma_invoice_av_billing_info_custom_fields',
  DELETE_PROFORMA_INVOICE_AV_BILLING_INFO_CUSTOM_FIELDS = 'delete_proforma_invoice_av_billing_info_custom_fields',
  READ_PROFORMA_INVOICE_BILLING_INFO_CUSTOM_FIELDS = 'read_proforma_invoice_billing_info_custom_fields',
  CREATE_PROFORMA_INVOICE_BILLING_INFO_CUSTOM_FIELDS = 'create_proforma_invoice_billing_info_custom_fields',
  UPDATE_PROFORMA_INVOICE_BILLING_INFO_CUSTOM_FIELDS = 'update_proforma_invoice_billing_info_custom_fields',
  DELETE_PROFORMA_INVOICE_BILLING_INFO_CUSTOM_FIELDS = 'delete_proforma_invoice_billing_info_custom_fields',
  READ_PROFORMA_INVOICE_HEADER_CUSTOM_FIELDS = 'read_proforma_invoice_header_custom_fields',
  CREATE_PROFORMA_INVOICE_HEADER_CUSTOM_FIELDS = 'create_proforma_invoice_header_custom_fields',
  UPDATE_PROFORMA_INVOICE_HEADER_CUSTOM_FIELDS = 'update_proforma_invoice_header_custom_fields',
  DELETE_PROFORMA_INVOICE_HEADER_CUSTOM_FIELDS = 'delete_proforma_invoice_header_custom_fields',
  READ_PROFORMA_INVOICE_TERMS_AND_NOTES_CUSTOM_FIELDS = 'read_proforma_invoice_terms_and_notes_custom_fields',
  CREATE_PROFORMA_INVOICE_TERMS_AND_NOTES_CUSTOM_FIELDS = 'create_proforma_invoice_terms_and_notes_custom_fields',
  UPDATE_PROFORMA_INVOICE_TERMS_AND_NOTES_CUSTOM_FIELDS = 'update_proforma_invoice_terms_and_notes_custom_fields',
  DELETE_PROFORMA_INVOICE_TERMS_AND_NOTES_CUSTOM_FIELDS = 'delete_proforma_invoice_terms_and_notes_custom_fields',
  READ_PROFORMA_INVOICE_AMOUNT_DUE_CUSTOM_FIELDS = 'read_proforma_invoice_amount_due_custom_fields',
  CREATE_PROFORMA_INVOICE_AMOUNT_DUE_CUSTOM_FIELDS = 'create_proforma_invoice_amount_due_custom_fields',
  UPDATE_PROFORMA_INVOICE_AMOUNT_DUE_CUSTOM_FIELDS = 'update_proforma_invoice_amount_due_custom_fields',
  DELETE_PROFORMA_INVOICE_AMOUNT_DUE_CUSTOM_FIELDS = 'delete_proforma_invoice_amount_due_custom_fields',
  CHANGE_PROFORMA_INVOICE_BILLING_INFO = 'change_proforma_invoice_billing_info',
  CREATE_PROFORMA_INVOICE_BILLING_INFO = 'create_proforma_invoice_billing_info',
  CHANGE_PROFORMA_INVOICE_AV_BILLING_INFO = 'change_proforma_invoice_av_billing_info',
  READ_UPDATE_INVOICE_BILLING_INFO_MODAL_BUTTON = 'read_update_invoice_billing_info_modal_button',
  CREATE_UPDATE_INVOICE_BILLING_INFO_MODAL_BUTTON_BILLING_INFO = 'create_update_invoice_billing_info_modal_button_billing_info',
  CHANGE_UPDATE_INVOICE_BILLING_INFO_MODAL_BUTTON_AV_BILLING_INFO = 'change_update_invoice_billing_info_modal_button_av_billing_info',
  READ_PROFORMA_INVOICE_TEAM_UPSALERS = 'read_proforma_invoice_team_upsalers',
  READ_PROFORMA_INVOICE_NOTES = 'read_proforma_invoice_notes',
  CHANGE_PROFORMA_INVOICE_NOTES = 'change_proforma_invoice_notes',
  READ_PROFORMA_INVOICE_TERMS = 'read_proforma_invoice_terms',
  CHANGE_PROFORMA_INVOICE_TERMS = 'change_proforma_invoice_terms',
  READ_PROFORMA_INVOICE_ITEMS_VOID_BUTTON = 'read_proforma_invoice_items_void_button',
  READ_PROFORMA_INVOICE_BATCH_ITEMS_VOID_BUTTON = 'read_proforma_invoice_batch_items_void_button',
  READ_PROFORMA_INVOICE_TOTALS_PREPAYMENT_FIELD = 'read_proforma_invoice_totals_prepayment_field',
  READ_PROFORMA_INVOICE_GENERAL_LEDGER_ID = 'read_proforma_invoice_general_ledger_id',
  READ_CONTRACTOR_INVOICES_PAGE = 'read_contractor_invoices_page',
  READ_CONTRACTOR_OVERDUE_INVOICES_PAGE = 'read_contractor_overdue_invoices_page',
  READ_CONTRACTOR_CURRENT_INVOICES_PAGE = 'read_contractor_current_invoices_page',
  READ_CONTRACTOR_OPEN_INVOICES_PAGE = 'read_contractor_open_invoices_page',
  READ_CONTRACTOR_SENT_INVOICES_PAGE = 'read_contractor_sent_invoices_page',
  READ_PAYMENTS_MENU_CONTRACTOR_INVOICES_LINK = 'read_payments_menu_contractor_invoices_link',
  READ_PAYMENTS_MENU_CONTRACTOR_CURRENT_INVOICES_LINK = 'read_payments_menu_contractor_current_invoices_link',
  READ_PAYMENTS_MENU_CONTRACTOR_OPEN_INVOICES_LINK = 'read_payments_menu_contractor_open_invoices_link',
  READ_PAYMENTS_MENU_CONTRACTOR_SENT_INVOICES_LINK = 'read_payments_menu_contractor_sent_invoices_link',
  READ_PAYMENTS_MENU_CONTRACTOR_OVERDUE_INVOICES_LINK = 'read_payments_menu_contractor_overdue_invoices_link',
  READ_CONTRACTOR_INVOICES_MONTH_TOTALS = 'read_contractor_invoices_month_totals',
  READ_CONTRACTOR_INVOICES_MONTH_TOTAL_AMOUNT = 'read_contractor_invoices_month_total_amount',
  READ_CONTRACTOR_INVOICES_TABLE_PARENT_INVOICE_FIELD = 'read_contractor_invoices_table_parent_invoice_field',
  READ_SEND_TO_EMAIL_PROFILE_EMAIL_FIELD = 'read_send_to_email_profile_email_field',
  READ_PAYMENTS_INVOICE_ITEMS_MY_ITEMS_ONLY_FIELD = 'read_payments_invoice_items_my_items_only_field',
  READ_PAYMENTS_INVOICE_ITEMS_GROUP_BY_FIELD = 'read_payments_invoice_items_group_by_field',
  READ_PAYMENTS_INVOICE_ITEMS_GROUP_BY_PROJECTS_OPTION = 'read_payments_invoice_items_group_by_projects_option',
  READ_PAYMENTS_INVOICE_ITEMS_GROUP_BY_TASKS_OPTION = 'read_payments_invoice_items_group_by_tasks_option',
  READ_PAYMENTS_INVOICE_ITEMS_GROUP_BY_ITEMS_OPTION = 'read_payments_invoice_items_group_by_items_option',
  READ_PAYMENTS_INVOICE_ITEMS_GROUP_BY_ITEM_CATEGORIES_OPTION = 'read_payments_invoice_items_group_by_item_categories_option',
  READ_PAYMENTS_INVOICE_ITEMS_GROUP_BY_ITEM_TAGS_OPTION = 'read_payments_invoice_items_group_by_item_tags_option',
  READ_PAYMENTS_INVOICE_ITEMS_TABLE_HEADER = 'read_payments_invoice_items_table_header',
  READ_PAYMENTS_INVOICE_ITEMS_TABLE_GROUPING = 'read_payments_invoice_items_table_grouping',
  READ_PAYMENTS_INVOICES_LIST_CHECKBOX = 'read_payments_invoices_list_checkbox',
  READ_PAYMENTS_INVOICE_PAYMENT_METHOD = 'read_payments_invoice_payment_method',
  READ_PAYMENTS_INVOICE_CREATE_INVOICE_BUTTON = 'read_payments_invoice_create_invoice_button',
  READ_PAYMENTS_INVOICES_LIST_PAYER = 'read_payments_invoices_list_payer',
  READ_PAYMENTS_INVOICE_PAYMENTS = 'read_payments_invoice_payments',
  READ_PAYMENTS_INVOICE_CURRENCY = 'read_payments_invoice_currency',
  READ_INVOICES_CHECK_AND_PAY_BUTTON = 'read_invoices_check_and_pay_button',
  READ_PAYMENTS_INVOICE_CURRENCY_CHANGE_BUTTON = 'read_payments_invoice_currency_change_button',
  READ_INVOICES_PAYONEER_V4_CHECK_AND_PAY_BUTTON = 'read_invoices_payoneer_v4_check_and_pay_button',
  READ_INVOICE_PAYONEER_V4_PAY_BUTTON = 'read_invoice_payoneer_v4_pay_button',
  READ_PROFORMA_INVOICE_CURRENCY = 'read_proforma_invoice_currency',
  READ_PAYMENTS_INVOICE_NAME = 'read_payments_invoice_name',
  READ_INVOICES_PERIOD_FILTER = 'read_invoices_period_filter',
  READ_INVOICES_GROUPING_FILTER = 'read_invoices_grouping_filter',
  READ_PAYMENTS_INVOICE_VAT_NOTE = 'read_payments_invoice_vat_note',
  CHANGE_PAYMENTS_INVOICE_DUE_DATE = 'change_payments_invoice_due_date',
  CHANGE_PAYMENTS_INVOICE_ISSUE_DATE = 'change_payments_invoice_issue_date',
  READ_PROFORMA_GENERAL_LEDGERS = 'read_proforma_general_ledgers',
  READ_PROFORMA_GENERAL_LEDGERS_WITH_ZERO_BALANCES = 'read_proforma_general_ledgers_with_zero_balances',
  READ_PAYMENTS_INVOICES_FILTERS = 'read_payments_invoices_filters',
  READ_PAYMENTS_INVOICES_ITEM_NAME_FILTER = 'read_payments_invoices_item_name_filter',
  READ_PAYMENTS_INVOICE_ITEMS_REMOVE_FROM_DRAFT_BUTTON = 'read_payments_invoice_items_remove_from_draft_button',
  READ_PAYMENTS_INVOICE_ITEMS_VOID_BUTTON = 'read_payments_invoice_items_void_button',
  READ_PAYMENTS_INVOICE_PAY_FROM_DEPOSIT_BUTTON = 'read_payments_invoice_pay_from_deposit_button',
  READ_PAY_FROM_DEPOSIT_AS_ASYNC = 'read_pay_from_deposit_as_async',
  READ_CANCEL_SMART_CONTRACTS_AS_ASYNC = 'read_cancel_smart_contracts_as_async',
  READ_CONFIRM_PAYMENT_AS_ASYNC = 'read_confirm_payment_as_async',
  READ_PAY_INTERNAL_AS_ASYNC = 'read_pay_internal_as_async',
  READ_PAYMENTS_INVOICES_PROJECT_CLOSURE_MONTH_GROUPING = 'read_payments_invoices_project_closure_month_grouping',
  READ_UNCOLLECTIBLE_INVOICE = 'read_uncollectible_invoice',
  READ_PROFORMA_INVOICE_STATUSES_FILTER_BADGES = 'read_proforma_invoice_statuses_filter_badges',
  READ_INVOICES_OPEN_INVOICE_BUTTON = 'read_invoices_open_invoice_button'
}

export const DEFAULT_TRANSACTION_FEE = 5;

export const INITIAL_INVOICES_FILTERS = {};
export const INITIAL_INVOICES_LIMIT = 20;
export const INITIAL_INVOICES_PAGE = 1;
export const INITIAL_INVOICES_SORT = [FetchInvoicesSortTypes.CREATED_AT_DESC];

export const invoiceI18nTextStatuses = {
  [InvoiceStatuses.SENT]: invoicesKeys.statuses.sent,
  [InvoiceStatuses.PAID]: invoicesKeys.statuses.paid,
  [InvoiceStatuses.PREPAID]: invoicesKeys.statuses.prepaid,
  [InvoiceStatuses.OPEN]: invoicesKeys.statuses.open,
  [InvoiceStatuses.CANCELED]: invoicesKeys.statuses.canceled,
  [InvoiceStatuses.UNCOLLECTIBLE]: invoicesKeys.statuses.uncollectible,
  [InvoiceStatuses.VOID]: invoicesKeys.statuses.void,
  [InvoiceStatuses.DRAFT]: invoicesKeys.statuses.draft,
  [InvoiceStatuses.NONE]: invoicesKeys.statuses.none
};
export const invoiceStatuses = [
  InvoiceStatuses.DRAFT,
  InvoiceStatuses.SENT,
  InvoiceStatuses.OPEN,
  InvoiceStatuses.PAID,
  InvoiceStatuses.CANCELED,
  InvoiceStatuses.VOID,
  InvoiceStatuses.UNCOLLECTIBLE
];

export const invoiceStatusesLabels = {
  [InvoiceStatuses.SENT]:
    'bg-gray-100 text-gray-800 dark:text-gray-50 dark:bg-gray-700 dark:bg-opacity-50',
  [InvoiceStatuses.PREPAID]:
    'bg-green-100 text-green-800 dark:text-green-50 dark:bg-green-900 dark:bg-opacity-50',
  [InvoiceStatuses.PAID]:
    'bg-green-100 text-green-800 dark:text-green-50 dark:bg-green-900 dark:bg-opacity-50',
  [InvoiceStatuses.OPEN]:
    'bg-yellow-100 text-yellow-800 dark:text-yellow-50 dark:bg-yellow-900 dark:bg-opacity-50',
  [InvoiceStatuses.CANCELED]:
    'bg-red-100 text-red-800 dark:text-red-50 dark:bg-red-900 dark:bg-opacity-50',
  [InvoiceStatuses.UNCOLLECTIBLE]:
    'bg-red-100 text-red-800 dark:text-red-50 dark:bg-red-900 dark:bg-opacity-50',
  [InvoiceStatuses.VOID]:
    'bg-red-800 text-red-50 dark:text-red-900 dark:bg-red-100',
  [InvoiceStatuses.DRAFT]:
    'bg-transparent text-gray-800 dark:text-gray-50 ring-1 ring-gray-200 dark:ring-gray-800 ring-inset',
  [InvoiceStatuses.NONE]:
    'bg-gray-100 text-gray-800 dark:text-gray-50 dark:bg-gray-700 dark:bg-opacity-50',
  [InvoiceStatuses.PROCESSING]:
    'bg-orange-100 text-orange-800 dark:text-orange-50 dark:bg-orange-900 dark:bg-opacity-50'
};

export const invoiceStatusesColors = {
  [InvoiceStatuses.SENT]: 'text-sky-600 dark:text-sky-400',
  [InvoiceStatuses.PAID]: 'text-green-600 dark:text-green-400',
  [InvoiceStatuses.PREPAID]: 'text-green-600 dark:text-green-400',
  [InvoiceStatuses.OPEN]: 'text-yellow-600 dark:text-yellow-400',
  [InvoiceStatuses.CANCELED]: 'text-red-600 dark:text-red-400',
  [InvoiceStatuses.UNCOLLECTIBLE]: 'text-red-600 dark:text-red-400',
  [InvoiceStatuses.VOID]: 'text-gray-600 dark:text-gray-400',
  [InvoiceStatuses.DRAFT]: 'text-gray-600 dark:text-gray-400',
  [InvoiceStatuses.NONE]: 'text-gray-600 dark:text-gray-400',
  [InvoiceStatuses.PROCESSING]: 'text-orange-600 dark:text-orange-400'
};

export const invoiceStatusesBgColors = {
  [InvoiceStatuses.SENT]: 'text-sky-800 bg-sky-200',
  [InvoiceStatuses.PAID]: 'text-green-800 bg-green-200',
  [InvoiceStatuses.PREPAID]: 'text-green-800 bg-green-200',
  [InvoiceStatuses.OPEN]: 'text-yellow-800 bg-yellow-200',
  [InvoiceStatuses.CANCELED]: 'text-red-800 bg-red-200',
  [InvoiceStatuses.UNCOLLECTIBLE]: 'text-red-800 bg-red-200',
  [InvoiceStatuses.VOID]: 'text-gray-800 bg-gray-200',
  [InvoiceStatuses.DRAFT]:
    'text-gray-800 bg-transparent ring-1 ring-gray-200 ring-inset',
  [InvoiceStatuses.NONE]: 'text-gray-800 bg-gray-200',
  [InvoiceStatuses.PROCESSING]: 'text-orange-800 bg-orange-200'
};

export const bgCrossedInvoiceStatuses = [
  InvoiceStatuses.VOID,
  InvoiceStatuses.CANCELED
];

export const paidInvoiceStatuses = [
  InvoiceStatuses.PAID,
  InvoiceStatuses.PREPAID
];

export const refundSlugFilter = InvoiceTypes.REFUND;

export const availableFilters = [
  InvoiceStatuses.SENT,
  InvoiceStatuses.OPEN,
  InvoiceStatuses.DRAFT,
  InvoiceStatuses.PAID,
  InvoiceStatuses.CANCELED
];

export const bannedInvoiceStatuses = [
  InvoiceStatuses.PAID,
  InvoiceStatuses.CANCELED,
  InvoiceStatuses.VOID,
  InvoiceStatuses.UNCOLLECTIBLE
];

export const enum InvoiceItemsGroupBy {
  ITEMS = 'items',
  ITEM_CATEGORIES = 'itemCategories',
  PROJECTS = 'projects',
  TASKS = 'tasks',
  ITEM_TAGS = 'itemTags'
}

export const enum InvoiceByTypeEnum {
  COMPANY = 'company',
  SHARE = 'share',
  SMART_CONTRACT = 'smart_contract',
  STATUS = 'status'
}

export const moneyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

export const itemsGroupByLocalStorageKey = 'invoice-items-group-by';

export const defaultViewSettingsState: InvoiceViewSettings = {
  viewMode: ProformaInvoiceFormViewMode.INVOICES,
  groupBy: ProformaInvoiceFormGroupByMode.PROJECT,
  enableGrouping: true
};

export const invoiceItemsViewCheckedItemsKey =
  'invoice-items-view-checked-items';
